(function(ns) {
	"use strict";

	$(function() {
		zzl.qs(".zzl-input-image").forEach(function(el) {
			new ns.ImageUploader(el);
		});
	});
	
})(zzl.uploader);
