$(function() {
	// 二重送信を防止する
	var submitted = false;
	$("form").on("submit", function(event) {
		if (submitted) {
			event.preventDefault();
		}
		submited = true;
	});

	// Enterキーによるフォームの送信を防止する
	$("form:not(.enter-to-submit)").on("keypress", "input:not([type=submit])", function(event) {
		if (event.keyCode === 13) {
			event.preventDefault();
		}
	});

	// 無効化されたリンクや、paginator の現在のページをクリックしても反応しないようにする
	$(document).on("click", ".pagination .active a, .disabled a, a.disabled", function(event) {
		event.preventDefault();
	});
	
	// エラー項目をハイライト
	$(".input-error").each(function() {
		if (/[^\s]/.test($(this).text())) {
			$(this).closest(".form-group").addClass("has-error");
		}
	});

	// 並べ替え
	if ($.fn.sortable) {
		$(".sortable").sortable({ update: function() { orderChanged($(this)) } }).disableSelection();
	}
	
	function orderChanged($list) {
		var index = 0;
		
		$list.children().each(function() {
			var $input = $(this).find(".sort-val");
			if ($input.length) {
				$input.val(index);
				index++;
			}
		});
	}
});
