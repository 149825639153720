/*
	テーブルの行の最初のデフォルトボタンまたはラジオ、チェックボックスをクリックできるようにする
*/
$(document).on("click", ".table-row-clickable", function(event) {
	var clickable = $(event.target).closest("a, button, input, select");
	if (clickable.length > 0) return;

	var row = $(this);
	var btn = row.find(".btn-default");
	if (btn.length === 1) {
		var href = btn.attr("href");
		if (href) {
			location.href = href;
		}
		return;
	}
	var radio = row.find("input[type='checkbox'], input[type='radio']");
	if (radio.length === 1 && $(event.target).closest(radio).length === 0) {
		radio.click();
		return;
	}
});
